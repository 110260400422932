import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { requestToken, logout } from './auth'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../keys'
import { headers } from './headers'

export interface ServerPayload {
  [key: string]: any
}

export interface DataWrapper<T> {
  data: T
}

export interface ServerPagination<T> {
  data: T[]
  meta: {
    pagination?: {
      total: number
      count: number
      per_page: number
      current_page: number
      total_pages: number
      links: []
    }
    cursor?: {
      current: number
      prev: number
      next: number
      count: number
    }
  }
}

export interface CursorPage {
  total: number
  count: number
  perPage: number
  currentPage: number
  totalPages: number
}

export interface CursorBasic {
  current: number
  previous: number
  next: number
  count: number
}

export interface Cursor {
  hasMore: boolean
  page?: CursorPage
  basic?: CursorBasic
}

export interface Pagination<T> {
  cursor: Cursor
  hasPagination?: boolean
  items: T[]
}

export interface Storage {
  hasItem: (key: string) => Promise<boolean>
  setItem: (key: string, value: string) => Promise<void>
  getItem: (key: string) => Promise<string>
  removeItem: (key: string) => Promise<void>
}

export type RequestMiddleware = (config: AxiosRequestConfig) => AxiosRequestConfig | void
export type ResponseMiddleware = (response: AxiosResponse<any>) => AxiosResponse<any> | void
export type ErrorMiddleware = (error: any) => Error | void

export interface Middleware {
  onRequest?: RequestMiddleware
  onResponse?: ResponseMiddleware
  onError?: ErrorMiddleware
}

export interface APIConfiguration {
  baseUrl: string
  storage: Storage
  middleware?: Middleware[]
  auth?: {
    clientId: string
    clientSecret: string
  }
}

let configuration: APIConfiguration | undefined
let axiosInstance: AxiosInstance

export const getConfig = () => configuration
export const getAxiosInstance = () => {
  return axiosInstance
}

const configureAPI = (config: APIConfiguration) => {
  if (!!getConfig()) {
    throw new Error('API has already been configured.')
  }

  configuration = config
  axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/`
  })

  // add headers
  axiosInstance.interceptors.request.use(async (axiosConfig) => {
    const { headers: initialHeaders = {} } = axiosConfig
    return {
      ...axiosConfig,
      headers: { ...headers, ...initialHeaders }
    }
  })

  if (!!config.middleware) {
    for (const middleware of config.middleware) {
      if (!!middleware.onRequest) {
        const handledOnRequest = (requestConfig: AxiosRequestConfig) => {
          const result = middleware.onRequest(requestConfig)
          if (!!result) {
            return result
          }
          return requestConfig
        }
        axiosInstance.interceptors.request.use(handledOnRequest)
      }

      if (!!middleware.onResponse) {
        const handledOnResponse = (response: AxiosResponse<any>) => {
          const result = middleware.onResponse(response)
          if (!!result) {
            return result
          }
          return response
        }
        axiosInstance.interceptors.response.use(handledOnResponse, (error) => {
          throw error
        })
      }

      if (!!middleware.onError) {
        const handledOnError = (error: any) => {
          const result = middleware.onError(error)
          if (!!result) {
            throw result
          }

          throw error
        }
        axiosInstance.interceptors.response.use((response) => response, handledOnError)
      }
    }
  }
}

export default configureAPI
